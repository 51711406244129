export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const marketplaceApi = $fetch.create({
    baseURL: config.public.marketplace.baseUrl + '/public',
    headers: {
      Accept: 'application/json'
    }
  })

  return {
    provide: {
      marketplaceApi
    }
  }
})
