export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.client) return

  const companySlug = to.params.slug?.toString() || 'meevo'

  let routeName = 'slug-appointment-new-step'
  if (companySlug === 'meevo') routeName = 'meevo-appointment-new-step'

  if (to.query?.serviceUuid && (to.name !== routeName || (to.name === routeName && to.params?.step !== '3'))) {
    return navigateTo({
      name: routeName,
      params: { step: '3' },
      query: { serviceUuid: to.query?.serviceUuid }
    })
  }

  if (to.query?.businessUnitUuid && (to.name !== routeName || (to.name === routeName && to.params?.step !== '2'))) {
    return navigateTo({
      name: routeName,
      params: { step: '2' },
      query: { businessUnitUuid: to.query?.businessUnitUuid }
    })
  }
})
