import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import minMax from 'dayjs/plugin/minMax'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/de'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(minMax)
dayjs.extend(duration)
dayjs.locale('de')
// dayjs.tz.setDefault('Europe/Berlin')

export const $dayjs = dayjs
