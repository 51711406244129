import validate from "/workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45validate_45slug_45global from "/workspace/app/middleware/01.validateSlug.global.ts";
import _02_45query_45params_45global from "/workspace/app/middleware/02.queryParams.global.ts";
import manifest_45route_45rule from "/workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45validate_45slug_45global,
  _02_45query_45params_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "signed-link": () => import("/workspace/app/middleware/signedLink.ts"),
  "validate-step": () => import("/workspace/app/middleware/validateStep.ts")
}