export const useStep: useStepReturn = () => {
  const { companySlug } = useMarketplace()
  const DEFAULT_STEPS = [
    {
      description: 'Wähle einen unserer Standorte aus.',
      hint: 'Bitte wähle Deinen bevorzugten Standort aus.',
      href: {
        name: companySlug.value === 'meevo' ? 'meevo-appointment-new-step' : 'slug-appointment-new-step',
        params: companySlug.value === 'meevo' ? { step: '1' } : { slug: companySlug.value, step: '1' }
      },
      id: '01',
      name: 'Standort',
      status: 'current',
      title: 'Wo dürfen wir Dich begrüßen?',
      component: companySlug.value === 'meevo' ? 'BusinessUnitStep' : 'MarketplaceBusinessUnitStep'
    },
    {
      description: 'Wählen einen Services aus. Überzeuge Dich selbst.',
      hint: 'Bitte wähle Deinen passenden Service aus.',
      href: {
        name: companySlug.value === 'meevo' ? 'meevo-appointment-new-step' : 'slug-appointment-new-step',
        params: companySlug.value === 'meevo' ? { step: '2' } : { slug: companySlug.value, step: '2' }
      },
      id: '02',
      name: 'Service',
      status: 'upcoming',
      title: 'Was können wir Dir Gutes tun?',
      component: companySlug.value === 'meevo' ? 'ServiceStep' : 'MarketplaceServiceStep'
    },
    {
      description: 'Wählen das für Dich passende Datum und beste Uhrzeit aus.',
      hint: 'Bitte wähle Datum und Uhrzeit aus.',
      href: {
        name: companySlug.value === 'meevo' ? 'meevo-appointment-new-step' : 'slug-appointment-new-step',
        params: companySlug.value === 'meevo' ? { step: '3' } : { slug: companySlug.value, step: '3' }
      },
      id: '03',
      name: 'Datum',
      status: 'upcoming',
      title: 'Wann würde es Dir am besten passen?',
      component: companySlug.value === 'meevo' ? 'DateStep' : 'MarketplaceDateStep'
    },
    {
      description: 'Nenne uns noch ein paar Infos zu dir und teile uns bei Bedarf noch ein Kommentar.',
      hint: 'Bitte teile uns noch ein paar Informationen von Dir mit.',
      href: {
        name: companySlug.value === 'meevo' ? 'meevo-appointment-new-step' : 'slug-appointment-new-step',
        params: companySlug.value === 'meevo' ? { step: '4' } : { slug: companySlug.value, step: '4' }
      },
      id: '04',
      name: 'Kontakt',
      status: 'upcoming',
      title: 'Wie können wir Dich erreichen?',
      component: companySlug.value === 'meevo' ? 'InfoStep' : 'MarketplaceInfoStep'
    }
  ] as Array<Step>

  const state = useState<UseStepState>('useStep', () => ({
    steps: useCloneDeep(DEFAULT_STEPS)
  }))

  const setStep = (step: number) => {
    const stepIndex = step - 1
    if (stepIndex < 0) return
    if (state.value.steps.length <= stepIndex) return
    useEach(state.value.steps, (step: Step, index: number) => {
      if (index < stepIndex) step.status = 'complete'
      if (index === stepIndex) step.status = 'current'
      if (index > stepIndex) step.status = 'upcoming'
    })
  }

  const setStepStatusViaIndex = (index: number, status: Step['status']) => {
    if (!state.value.steps[index]) return
    state.value.steps[index].status = status
  }

  const nextStep = () => {
    const currentIndex = useFindIndex(state.value.steps, { status: 'current' })
    if (currentIndex === -1) return
    if (state.value.steps.length - 1 <= currentIndex) {
      setStepStatusViaIndex(currentIndex, 'complete')
      return
    }
    setStepStatusViaIndex(currentIndex, 'complete')
    setStepStatusViaIndex(currentIndex + 1, 'current')
  }

  const previousStep = () => {
    const currentIndex = useFindIndex(state.value.steps, { status: 'current' })
    if (currentIndex === -1 || currentIndex === 0) return
    setStepStatusViaIndex(currentIndex, 'upcoming')
    setStepStatusViaIndex(currentIndex - 1, 'current')
  }

  const reset = () => {
    state.value.steps = useCloneDeep(DEFAULT_STEPS)
  }

  const allStepsComplete = computed(() => state.value.steps.every((step: Step) => step.status === 'complete'))
  const currentStepIndex = computed(() => {
    const current = useFindIndex(state.value.steps, { status: 'current' })
    return current === -1 ? state.value.steps.length : current + 1
  })
  const currentStep = computed(() => useFind(state.value.steps, { status: 'current' }))
  const totalSteps = computed(() => state.value.steps.length)

  return {
    setStep,
    nextStep,
    previousStep,
    reset,
    allStepsComplete,
    currentStepIndex,
    currentStep,
    totalSteps,
    ...toRefs(state.value)
  }
}
