import { default as _91step_934iqDXwFqCCMeta } from "/workspace/app/pages/[slug]/appointment/new/[step].vue?macro=true";
import { default as _91uuid_93XKY5lspDEWMeta } from "/workspace/app/pages/[slug]/appointment/success/[uuid].vue?macro=true";
import { default as indexv6WtWCHXhDMeta } from "/workspace/app/pages/[slug]/index.vue?macro=true";
import { default as index6n3ZPBUsMaMeta } from "/workspace/app/pages/index.vue?macro=true";
import { default as cancelXZJgFYCLseMeta } from "/workspace/app/pages/meevo/appointment/cancel.vue?macro=true";
import { default as _91step_93k7GDQtu2gqMeta } from "/workspace/app/pages/meevo/appointment/new/[step].vue?macro=true";
import { default as _91uuid_93UnBId26DGXMeta } from "/workspace/app/pages/meevo/appointment/success/[uuid].vue?macro=true";
import { default as welcome6I3FOUSMQaMeta } from "/workspace/app/pages/meevo/appointment/welcome.vue?macro=true";
import { default as indexmzbf3XyCkMMeta } from "/workspace/app/pages/meevo/index.vue?macro=true";
export default [
  {
    name: "slug-appointment-new-step",
    path: "/:slug()/appointment/new/:step()",
    meta: _91step_934iqDXwFqCCMeta || {},
    component: () => import("/workspace/app/pages/[slug]/appointment/new/[step].vue")
  },
  {
    name: "slug-appointment-success-uuid",
    path: "/:slug()/appointment/success/:uuid()",
    meta: _91uuid_93XKY5lspDEWMeta || {},
    component: () => import("/workspace/app/pages/[slug]/appointment/success/[uuid].vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/workspace/app/pages/[slug]/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index6n3ZPBUsMaMeta || {},
    component: () => import("/workspace/app/pages/index.vue")
  },
  {
    name: "meevo-appointment-cancel",
    path: "/meevo/appointment/cancel",
    component: () => import("/workspace/app/pages/meevo/appointment/cancel.vue")
  },
  {
    name: "meevo-appointment-new-step",
    path: "/meevo/appointment/new/:step()",
    meta: _91step_93k7GDQtu2gqMeta || {},
    component: () => import("/workspace/app/pages/meevo/appointment/new/[step].vue")
  },
  {
    name: "meevo-appointment-success-uuid",
    path: "/meevo/appointment/success/:uuid()",
    meta: _91uuid_93UnBId26DGXMeta || {},
    component: () => import("/workspace/app/pages/meevo/appointment/success/[uuid].vue")
  },
  {
    name: "meevo-appointment-welcome",
    path: "/meevo/appointment/welcome",
    meta: welcome6I3FOUSMQaMeta || {},
    component: () => import("/workspace/app/pages/meevo/appointment/welcome.vue")
  },
  {
    name: "meevo",
    path: "/meevo",
    component: () => import("/workspace/app/pages/meevo/index.vue")
  }
]